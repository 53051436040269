import { createPromiseWithState } from "$frontend/utils/promise";
import { ClientOnlyError } from "bun-react-ssr/client";
import { createContext, useContext, useMemo } from "react";
export const LogtoContext = createContext({
    refresh () {}
});
export function useLogto() {
    return useContext(LogtoContext);
}
export const AuthPromiseContext = createContext(createPromiseWithState());
export const AuthContext = createContext(void 0);
export function useAuthPromise() {
    if ("undefined" == typeof window) throw new ClientOnlyError();
    return useContext(AuthPromiseContext);
}
export function useAuth() {
    return useContext(AuthContext);
}
export function useUserId() {
    var _useUserInfo;
    return null == (_useUserInfo = useUserInfo()) ? void 0 : _useUserInfo.id;
}
export function useUserInfo() {
    let token = useAuth();
    return useMemo(()=>{
        try {
            if ("string" != typeof token || !token) return;
            {
                let parsed = JSON.parse(atob(token.split(".")[1]));
                return {
                    id: parsed.sub,
                    roles: parsed.role_names
                };
            }
        } catch (e) {
            return;
        }
    }, [
        token
    ]);
}
