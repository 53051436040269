import { useCallback, useRef, useState, useSyncExternalStore } from "react";
export function useSelfRefresh(fn, getServerSnapshot, dependencies) {
    let [listeners] = useState(()=>new Set()), snapshot = useRef(null), serverSnapshot = useRef(null);
    return useSyncExternalStore(useCallback((cb)=>(listeners.add(cb), ()=>listeners.delete(cb)), []), useCallback(()=>{
        var _current;
        return null != (_current = snapshot.current) ? _current : snapshot.current = fn(()=>{
            snapshot.current = null, listeners.forEach((cb)=>cb());
        });
    }, dependencies), useCallback(()=>{
        var _current;
        return null != (_current = serverSnapshot.current) ? _current : serverSnapshot.current = getServerSnapshot();
    }, []));
}
