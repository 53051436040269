import { useCallback, useEffect, useRef, useState } from "react";
export function usePreMountState(initial) {
    let initialRef = useRef(initial), mountState = useRef(!1), [state, setState] = useState(initialRef.current);
    return useEffect(()=>(setState(initialRef.current), mountState.current = !0, ()=>{
            mountState.current = !1;
        }), []), [
        state,
        useCallback((action)=>{
            mountState.current ? setState(action) : initialRef.current = action instanceof Function ? action(initialRef.current) : action;
        }, [])
    ];
}
