import { navigate } from "bun-react-ssr/router";
import { useEventHandler } from "./useEventHandler";
import { isAndroid } from "react-device-detect";
let api = globalThis;
isAndroid && setTimeout(()=>{
    if (0 === embedInjectFix()) {
        let orig = history.replaceState;
        history.replaceState = (state, unused, url)=>{
            if (url) return orig.call(history, state, unused, url);
            let parsed = new URL(location.href);
            return parsed.searchParams.set("workaround", Math.random() + ""), orig.call(history, state, unused, parsed);
        };
    }
}, 1000);
export const isEmbedded = ()=>!!api.EMBED;
export const embedKeyboardFix = ()=>{
    var _api_EMBED, _api_EMBED_keyboardFix;
    return null != (_api_EMBED_keyboardFix = null == (_api_EMBED = api.EMBED) ? void 0 : _api_EMBED.keyboardFix) ? _api_EMBED_keyboardFix : 0;
};
export const embedAndroidShareFix = ()=>{
    var _api_EMBED, _api_EMBED_androidShareFix;
    return null != (_api_EMBED_androidShareFix = null == (_api_EMBED = api.EMBED) ? void 0 : _api_EMBED.androidShareFix) ? _api_EMBED_androidShareFix : 0;
};
export const embedInjectFix = ()=>{
    var _api_EMBED, _api_EMBED_injectFix;
    return api.EMBED ? null != (_api_EMBED_injectFix = null == (_api_EMBED = api.EMBED) ? void 0 : _api_EMBED.injectFix) ? _api_EMBED_injectFix : 0 : 1;
};
export function reload() {
    location.reload();
}
export function useEmbedLink(url, opt) {
    return useEventHandler(()=>{
        embedNavigate(url, opt);
    });
}
export function embedNavigate(url, opt) {
    var _api_EMBED;
    if ((!(null == opt ? void 0 : opt.allowSeamless) || !toggleSeamless(!0)) && (null == (_api_EMBED = api.EMBED) ? void 0 : _api_EMBED.navigate)) {
        api.EMBED.navigate(url);
        return;
    }
    navigate(url, opt);
}
export function embedBack() {
    var _api_EMBED;
    if (null == (_api_EMBED = api.EMBED) ? void 0 : _api_EMBED.back) {
        api.EMBED.back();
        return;
    }
    window.history.length > 1 ? window.history.back() : navigate("/", {
        replace: !0
    });
}
export function embedLogin() {
    var _api_EMBED;
    return null != (_api_EMBED = api.EMBED) && !!_api_EMBED.login && (api.EMBED.login(), !0);
}
export function openWallet() {
    var _api_EMBED;
    return null != (_api_EMBED = api.EMBED) && !!_api_EMBED.openWallet && (api.EMBED.openWallet(), !0);
}
export function openInAppLink(link) {
    var _api_EMBED;
    return null != (_api_EMBED = api.EMBED) && !!_api_EMBED.openInAppLink && (api.EMBED.openInAppLink(link), !0);
}
export function openDeepLink(link) {
    var _api_EMBED;
    return null != (_api_EMBED = api.EMBED) && !!_api_EMBED.openDeepLink && (api.EMBED.openDeepLink(link), !0);
}
export function toggleSeamless(seamless) {
    var _api_EMBED;
    return null != (_api_EMBED = api.EMBED) && !!_api_EMBED.toggleSeamless && (api.EMBED.toggleSeamless(seamless), !0);
}
export function shareable() {
    var _api_EMBED;
    return (null == (_api_EMBED = api.EMBED) ? void 0 : _api_EMBED.share) || navigator.share;
}
export function share(param) {
    var _api_EMBED;
    let { title = document.title, url = location.href, image } = void 0 === param ? {} : param;
    if (null == (_api_EMBED = api.EMBED) ? void 0 : _api_EMBED.share) {
        api.EMBED.share(title, url, image);
        return;
    }
    if (navigator.share) {
        if (image) {
            (async ()=>{
                let data = await fetch(image), file = new File([
                    await data.blob()
                ], title + ".png");
                navigator.share({
                    title,
                    url,
                    files: [
                        file
                    ]
                });
            })().catch(console.error);
            return;
        }
        navigator.share({
            title,
            url
        });
    }
}
